import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import VerticalTestimonial from "../components/vertical-testimonial";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import { Button } from "react-bootstrap";
const ContactUsPage = ({ data }) => {
	const { siteUrl } = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const ogImg = `${siteUrl}${schemaImage}`;
	const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
	const nick = data.nick.childImageSharp.gatsbyImageData;
	const jessC = data.jessC.childImageSharp.gatsbyImageData;
	const banner = data.banner.childImageSharp.fluid;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	const html = `<form
      action="https://formspree.io/f/xjvjwzwp"
      method="POST">
    <div class="form-group">
        <input class="form-control bg-light border-0 py-5" placeholder="Name" type="text" name="name">
    </div>
    <div class="form-group">
        <input class="form-control bg-light border-0 py-5" placeholder="Email Address" type="email" name="_replyto">    
    </div>
    <div class="form-group">
        <input class="form-control bg-light border-0 py-5" placeholder="Telephone Number" type="text" name="telephone">    
    </div>
    <div class="form-group">
     <input class="form-control bg-light border-0 py-5" name="findme" placeholder="How and where did you find me?"></input>      
    </div>   
    <div class="form-group">
     <textarea class="form-control bg-light border-0 py-5" rows="3" name="how can i help you?" placeholder="How can I help you?"></textarea>      
    </div>      

    <div class="g-recaptcha my-3" data-sitekey="6LemGhEbAAAAAOsi2poxFxt4QMAR_IyX6JorV_30"></div>   
    <button class="btn btn-outline-primary mt-3" id="contact-send-btn" type="submit">Send Message</button>      
    </form>`;

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Contact Jason Cornes"
				description="It will be good to meet you and listen about your circumstances, what you’d like to achieve and discuss how I can help. "
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Jason Cornes",
					description:
						"It will be good to meet you and listen about your circumstances, what you’d like to achieve and discuss how I can help. ",
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Contact Jason Cornes",
						},
					],
				}}
			/>
			<section>
				<Container>
					<Row className="pt-5 mb-8">
						<Col lg={7}>
							<h1 className="text-primary mb-5">Book your discovery meeting</h1>
							<p>
								The first step is for us to have a discovery meeting over Zoom.
								It will be good to meet you and listen about your circumstances,
								what you’d like to achieve and discuss how I can help. I won’t
								sell to you. Instead, I will give you feedback and suggest a way
								forward that I think would be most helpful to you. Please get in
								touch by completing the contact form, and we’ll get a day and
								time into our diaries.
							</p>
							<Button
								className="cta-btn mb-4 mt-2 cta-btn-gtm"
								variant="secondary"
								size="lg"
								as={Link}
								to="#form"
							>
								Book Now
							</Button>
							<p className="hind-bold">
								Please answer the questions on the{" "}
								<a href="#form">contact form</a> and I'll get back to you by
								email and text within 24 hours
							</p>
						</Col>

						<Col
							className="pb-5 pb-lg-0"
							xs={{ span: 12, order: "first" }}
							lg={{ span: 5, order: "last" }}
						>
							<GatsbyImage className="w-100" image={heroImg} />{" "}
						</Col>
					</Row>
					<Row>
						<Col>
							<h2 className="text-center text-primary">
								MY CLIENTS SAY IT BEST
							</h2>
						</Col>
					</Row>
					<Row>
						<Col className="mb-2">
							<p className="text-center">
								Wondering about the benefits of working together? No-one
								explains it better than more than 50 of my happy clients.
							</p>
						</Col>
					</Row>

					<Row className="g-5 pb-5 pb-lg-7">
						<Col lg={6}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/sp2-RfrN7jU"
									title="Testimonial | Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>

						<Col lg={6}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/obUlkJ1DiRI"
									title="Testimonial Two | Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
					</Row>
				</Container>
				{/* <BackgroundImage
					className="mb-6"
					style={{ backgroundPosition: "top" }}
					tag="section"
					fluid={banner}
					alt="Jason Cornes delivering a Business Coaching session in London"
				>
					<Container className="py-5 py-lg-8">
						<Row className="text-center">
							<Col>
								<h4 className="text-white pb-3">
									Please tell me a bit about your current situation? What is
									going well? What are your challenges? What does success look
									like to you?
								</h4>
							</Col>
						</Row>
					</Container>
				</BackgroundImage> */}
				<Container>
					<Row id="form" className="justify-content-center py-5 py-lg-7">
						<Col lg={7}>
							<h2 className="text-primary text-center pb-4">
								Book a discovery meeting
							</h2>{" "}
							<div
								style={{ boxShadow: "0px 3px 6px #00000029" }}
								className=" p-4"
							>
								<ContactForm />
								{/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
							</div>
						</Col>
					</Row>
				</Container>

				<Container>
					<Row className="py-5 py-lg-7 align-items-center">
						<Col className=" pb-5 pb-lg-0" lg={6}>
							{" "}
							<h2 className="  text-primary"> Get in touch</h2>
							<Row>
								<Col className="">
									<h3 className="mt-4 text-primary">Opening Times</h3>
									<p className="text-primary ">
										Monday-Friday 6am-5pm
										<br />
										Saturday 8am-1pm{" "}
									</p>
									<h3 className="text-primary ">Email</h3>
									<a
										className="text-primary email-link "
										href="mailto:jason@jasoncornes.co.uk"
									>
										jason@jasoncornes.co.uk
									</a>
									<h3 className="text-primary mt-4 ">London</h3>
									<p>
										<MdLocationOn />
										{` `}
										<a href="https://maps.google.com/maps?cid=13793948166534813390&_ga=2.197666607.1046404723.1622964383-362308339.1620228280">
											Kemp House, 152-160, City Rd, London, EC1V 2NX
										</a>
									</p>
									<p>
										<MdPhone />
										{` `}
										<a className=" phone-number" href="tel:02030518763">
											020 3051 8763
										</a>
									</p>
									<h3 className="text-primary mt-4 ">New York</h3>
									<p>
										<MdLocationOn />
										{` `}
										<a href="https://maps.app.goo.gl/Nhzz9DaFHAqZwBKY8">
											580 Fifth Avenue, Suite 820 , New York, NY 10036
										</a>
									</p>
									<p>
										<MdPhone />
										{` `}
										<a className=" phone-number" href="tel:2123308067">
											212 330 8067
										</a>
									</p>
								</Col>
							</Row>{" "}
						</Col>

						<Col lg={6}>
							{" "}
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2276047284086!2d-0.0891169!3d51.5273851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbf6df2c6247556ce!2sJason%20Cornes%20Business%20%26%20Executive%20Coach!5e0!3m2!1sen!2suk!4v1661267905624!5m2!1sen!2suk"
								width="100%"
								height="450"
								style="border:0;"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
							></iframe>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		banner: file(relativePath: { eq: "jason-client-meeting-3.jpg" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		heroImg: file(relativePath: { eq: "Jason New York.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(quality: 100, width: 400)
			}
		}
		nick: file(relativePath: { eq: "testimonials/nick-bryant.jpg" }) {
			childImageSharp {
				gatsbyImageData(quality: 100, width: 400)
			}
		}

		jessC: file(relativePath: { eq: "testimonials/Jessica Cox.jpg" }) {
			childImageSharp {
				gatsbyImageData(quality: 100, width: 400)
			}
		}
	}
`;

export default ContactUsPage;
