import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
function ContactForm() {
	const [state, handleSubmit] = useForm("xjvjwzwp");
	if (state.succeeded) {
		return (
			<div>
				<Form onSubmit={handleSubmit}>
					<Form.Group controlId="Name">
						<Form.Label>Name</Form.Label>
						<Form.Control
							className=" bg-light border-0 py-3"
							type="text"
							name="name"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />

					<Form.Group className="mt-3" controlId="email">
						<Form.Label>Email Address</Form.Label>
						<Form.Control
							className=" bg-light border-0  py-3"
							type="email"
							name="_replyto"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className="mt-3" controlId="telephone">
						<Form.Label>Telephone Number</Form.Label>
						<Form.Control
							className=" bg-light border-0  py-3"
							type="tel"
							name="telephone"
						/>
					</Form.Group>
					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mt-3" controlId="website-address">
						<Form.Label>Website Address</Form.Label>
						<Form.Control
							className=" bg-light border-0  py-3"
							type="text"
							name="website-address"
						/>
					</Form.Group>
					<ValidationError
						prefix="website-address"
						field="website-address"
						errors={state.errors}
					/>
					<Form.Group
						className="mt-3"
						controlId="whatPromptedYouToSeekCoachingServicesAtThisTime"
					>
						<Form.Label>
							What prompted you to seek coaching services at this time?
						</Form.Label>
						<Form.Control
							className=" bg-light border-0 py-3 "
							rows="3"
							name="What prompted you to seek coaching services at this time?"
							as="textarea"
						/>
					</Form.Group>
					<ValidationError
						prefix="What prompted you to seek coaching services at this time?"
						field="whatPromptedYouToSeekCoachingServicesAtThisTime"
						errors={state.errors}
					/>
					<Form.Group
						className="mt-3"
						controlId="canYouDescribeSomeOfTheKeyChallengesYouAreCurrentlyFacingInYourBusinessOrPractice"
					>
						<Form.Label>
							Can you describe some of the key challenges you are currently
							facing in your business or practice?
						</Form.Label>
						<Form.Control
							className=" bg-light border-0 py-3 "
							rows="3"
							name="Can you describe some of the key challenges you are currently facing in your business or practice?"
							as="textarea"
						/>
					</Form.Group>
					<ValidationError
						prefix="Can you describe some of the key challenges you are currently facing in your business or practice?"
						field="canYouDescribeSomeOfTheKeyChallengesYouAreCurrentlyFacingInYourBusinessOrPractice"
						errors={state.errors}
					/>
					<Form.Group
						className="mt-3"
						controlId="whatSpecificGoalsAreYouHopingToAchieveThroughCoaching"
					>
						<Form.Label>
							What specific goals are you hoping to achieve through coaching?
						</Form.Label>
						<Form.Control
							className=" bg-light border-0 py-3 "
							rows="3"
							name="What specific goals are you hoping to achieve through coaching?"
							as="textarea"
						/>
					</Form.Group>
					<ValidationError
						prefix="What specific goals are you hoping to achieve through coaching?"
						field="whatSpecificGoalsAreYouHopingToAchieveThroughCoaching"
						errors={state.errors}
					/>
					<Form.Group
						className="mt-3"
						controlId="doYouHaveATimelineInMindForAchievingTheseGoals"
					>
						<Form.Label>
							Do you have a timeline in mind for achieving these goals?
						</Form.Label>
						<Form.Control
							type="text"
							className=" bg-light border-0  py-3"
							name="Do you have a timeline in mind for achieving these goals?"
						/>
					</Form.Group>
					<ValidationError
						prefix="Do you have a timeline in mind for achieving these goals?"
						field="doYouHaveATimelineInMindForAchievingTheseGoals"
						errors={state.errors}
					/>
					<Form.Group
						className="mt-3"
						controlId="canYouProvideAnApproximateRangeOfYourBusinesssAnnualOrMonthlyTurnover"
					>
						<Form.Label>
							Can you provide an approximate range of your business's annual or
							monthly turnover?
						</Form.Label>
						<Form.Control
							type="text"
							className=" bg-light border-0  py-3"
							name="Can you provide an approximate range of your business's annual or monthly turnover?"
						/>
					</Form.Group>
					<ValidationError
						prefix="Can you provide an approximate range of your business's annual or monthly turnover?"
						field="canYouProvideAnApproximateRangeOfYourBusinesssAnnualOrMonthlyTurnover"
						errors={state.errors}
					/>
					<Form.Group
						className="mt-3"
						controlId="whichOfTheFollowingCoachingPackagesAreYouInterestedIn"
					>
						<Form.Label>
							Which of the following coaching packages are you interested in?
						</Form.Label>
						<Form.Select
							style={{ fontSize: "90%" }}
							name="Which of the following coaching packages are you interested in?"
							className=" hind-light bg-light border-0 w-100 px-3 py-3"
						>
							<option className="hind-light" value="" selected disabled hidden>
								Select An Option
							</option>
							<option className="hind-light">Problem Buster Package</option>
							<option className="hind-light">Business MOT/Health Check</option>
							<option className="hind-light">
								6 months Business & Executive Coaching
							</option>
							<option className="hind-light">
								12 months Business & Executive Coaching
							</option>
						</Form.Select>
					</Form.Group>
					<ValidationError
						prefix="Which of the following coaching packages are you interested in?"
						field="whichOfTheFollowingCoachingPackagesAreYouInterestedIn"
						errors={state.errors}
					/>

					<Form.Group className="mt-3" controlId="howDidYouFindMe">
						<Form.Label>How did you find me?</Form.Label>
						<Form.Control
							type="text"
							className=" bg-light border-0  py-3"
							name="How did you find me?"
						/>
					</Form.Group>
					<ValidationError
						prefix="How did you find me?"
						field="howDidYouFindMe"
						errors={state.errors}
					/>

					<Form.Group
						className="mt-3"
						controlId="doYouHaveAnyOtherCommentsOrQuestions"
					>
						<Form.Label>
							Do you have any other comments or questions?
						</Form.Label>
						<Form.Control
							className=" bg-light border-0 py-3 "
							rows="3"
							name="Do you have any other comments or questions?"
							as="textarea"
						/>
					</Form.Group>
					<ValidationError
						prefix="Do you have any other comments or questions?"
						field="doYouHaveAnyOtherCommentsOrQuestions"
						errors={state.errors}
					/>

					<div
						class="g-recaptcha my-3"
						data-sitekey="6LemGhEbAAAAAOsi2poxFxt4QMAR_IyX6JorV_30"
					></div>
					<button
						className="btn btn-secondary  mt-3"
						id="contact-send-btn"
						type="submit"
						disabled={state.submitting}
					>
						Send Message
					</button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form onSubmit={handleSubmit}>
			<Form.Group controlId="Name">
				<Form.Label>Name</Form.Label>
				<Form.Control
					className=" bg-light border-0 py-3"
					type="text"
					name="name"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="mt-3" controlId="email">
				<Form.Label>Email Address</Form.Label>
				<Form.Control
					className=" bg-light border-0  py-3"
					type="email"
					name="_replyto"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mt-3" controlId="telephone">
				<Form.Label>Telephone Number</Form.Label>
				<Form.Control
					className=" bg-light border-0  py-3"
					type="tel"
					name="telephone"
				/>
			</Form.Group>
			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mt-3" controlId="website-address">
				<Form.Label>Website Address</Form.Label>
				<Form.Control
					className=" bg-light border-0  py-3"
					type="text"
					name="website-address"
				/>
			</Form.Group>
			<ValidationError
				prefix="website-address"
				field="website-address"
				errors={state.errors}
			/>
			<Form.Group
				className="mt-3"
				controlId="whatPromptedYouToSeekCoachingServicesAtThisTime"
			>
				<Form.Label>
					What prompted you to seek coaching services at this time?
				</Form.Label>
				<Form.Control
					className=" bg-light border-0 py-3 "
					rows="3"
					name="What prompted you to seek coaching services at this time?"
					as="textarea"
				/>
			</Form.Group>
			<ValidationError
				prefix="What prompted you to seek coaching services at this time?"
				field="whatPromptedYouToSeekCoachingServicesAtThisTime"
				errors={state.errors}
			/>
			<Form.Group
				className="mt-3"
				controlId="canYouDescribeSomeOfTheKeyChallengesYouAreCurrentlyFacingInYourBusinessOrPractice"
			>
				<Form.Label>
					Can you describe some of the key challenges you are currently facing
					in your business or practice?
				</Form.Label>
				<Form.Control
					className=" bg-light border-0 py-3 "
					rows="3"
					name="Can you describe some of the key challenges you are currently facing in your business or practice?"
					as="textarea"
				/>
			</Form.Group>
			<ValidationError
				prefix="Can you describe some of the key challenges you are currently facing in your business or practice?"
				field="canYouDescribeSomeOfTheKeyChallengesYouAreCurrentlyFacingInYourBusinessOrPractice"
				errors={state.errors}
			/>
			<Form.Group
				className="mt-3"
				controlId="whatSpecificGoalsAreYouHopingToAchieveThroughCoaching"
			>
				<Form.Label>
					What specific goals are you hoping to achieve through coaching?
				</Form.Label>
				<Form.Control
					className=" bg-light border-0 py-3 "
					rows="3"
					name="What specific goals are you hoping to achieve through coaching?"
					as="textarea"
				/>
			</Form.Group>
			<ValidationError
				prefix="What specific goals are you hoping to achieve through coaching?"
				field="whatSpecificGoalsAreYouHopingToAchieveThroughCoaching"
				errors={state.errors}
			/>
			<Form.Group
				className="mt-3"
				controlId="doYouHaveATimelineInMindForAchievingTheseGoals"
			>
				<Form.Label>
					Do you have a timeline in mind for achieving these goals?
				</Form.Label>
				<Form.Control
					type="text"
					className=" bg-light border-0  py-3"
					name="Do you have a timeline in mind for achieving these goals?"
				/>
			</Form.Group>
			<ValidationError
				prefix="Do you have a timeline in mind for achieving these goals?"
				field="doYouHaveATimelineInMindForAchievingTheseGoals"
				errors={state.errors}
			/>
			<Form.Group
				className="mt-3"
				controlId="canYouProvideAnApproximateRangeOfYourBusinesssAnnualOrMonthlyTurnover"
			>
				<Form.Label>
					Can you provide an approximate range of your business's annual or
					monthly turnover?
				</Form.Label>
				<Form.Control
					type="text"
					className=" bg-light border-0  py-3"
					name="Can you provide an approximate range of your business's annual or monthly turnover?"
				/>
			</Form.Group>
			<ValidationError
				prefix="Can you provide an approximate range of your business's annual or monthly turnover?"
				field="canYouProvideAnApproximateRangeOfYourBusinesssAnnualOrMonthlyTurnover"
				errors={state.errors}
			/>
			<Form.Group
				className="mt-3"
				controlId="whichOfTheFollowingCoachingPackagesAreYouInterestedIn"
			>
				<Form.Label>
					Which of the following coaching packages are you interested in?
				</Form.Label>
				<Form.Select
					style={{ fontSize: "90%" }}
					name="Which of the following coaching packages are you interested in?"
					className=" hind-light bg-light border-0 w-100 px-3 py-3"
				>
					<option className="hind-light" value="" selected disabled hidden>
						Select An Option
					</option>
					<option className="hind-light">Problem Buster Package</option>
					<option className="hind-light">Business MOT/Health Check</option>
					<option className="hind-light">
						6 months Business & Executive Coaching
					</option>
					<option className="hind-light">
						12 months Business & Executive Coaching
					</option>
				</Form.Select>
			</Form.Group>
			<ValidationError
				prefix="Which of the following coaching packages are you interested in?"
				field="whichOfTheFollowingCoachingPackagesAreYouInterestedIn"
				errors={state.errors}
			/>

			<Form.Group className="mt-3" controlId="howDidYouFindMe">
				<Form.Label>How did you find me?</Form.Label>
				<Form.Control
					type="text"
					className=" bg-light border-0  py-3"
					name="How did you find me?"
				/>
			</Form.Group>
			<ValidationError
				prefix="How did you find me?"
				field="howDidYouFindMe"
				errors={state.errors}
			/>

			<Form.Group
				className="mt-3"
				controlId="doYouHaveAnyOtherCommentsOrQuestions"
			>
				<Form.Label>Do you have any other comments or questions?</Form.Label>
				<Form.Control
					className=" bg-light border-0 py-3 "
					rows="3"
					name="Do you have any other comments or questions?"
					as="textarea"
				/>
			</Form.Group>
			<ValidationError
				prefix="Do you have any other comments or questions?"
				field="doYouHaveAnyOtherCommentsOrQuestions"
				errors={state.errors}
			/>

			<div
				class="g-recaptcha my-3"
				data-sitekey="6LemGhEbAAAAAOsi2poxFxt4QMAR_IyX6JorV_30"
			></div>
			<button
				className="btn btn-secondary  mt-3"
				id="contact-send-btn"
				type="submit"
				disabled={state.submitting}
			>
				Send Message
			</button>
		</Form>
	);
}
export default ContactForm;
