import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";

const VerticalTestimonial = ({
	name,
	review,
	linkedIn,
	profilePic,
	showLinkedIn,
}) => {
	const linkedInVisible = showLinkedIn || "d-block";

	return (
		<Col
			xs={12}
			md={6}
			className="  text-center testimonial-image pb-4 pb-lg-0"
		>
			<GatsbyImage
				className="mb-4"
				image={profilePic}
				style={{ borderRadius: "100%", maxWidth: "200px" }}
			/>
			<h6 className="text-info text-center">{name}</h6>
			<p className="text-left">{review}</p>

			<a
				rel="noreferrer"
				className={`${linkedInVisible}`}
				href={linkedIn}
				target="_blank"
			>
				<FaLinkedin />
			</a>

			<Link className="hind-bold" to="/written-testimonials">
				Read more
			</Link>
		</Col>
	);
};

export default VerticalTestimonial;
